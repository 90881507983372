import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './style'
import { Card, CardContent, Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import { isScreenBiggerThan } from '../../../utils/parse'
import { useMobileStyles } from './mobileStyle'
import { AiTableAction, AiTableState } from '../../AiMainTable/state'
import { useState } from '@hookstate/core'

export default function StockAiScore({ code }) {
  const classes = useStyles()
  const mobileClasses = useMobileStyles()
  const { t, i18n } = useTranslation()
  const aiListState = useState(AiTableState.list)

  useEffect(() => {
    AiTableAction.loadAiRating(0, 10, 'rank:asc', '', { code })()
  }, [])

  return isScreenBiggerThan('md') ? (
    aiListState.data.length === 0 ? (
      <></>
    ) : (
      <Box component="span" m={1}>
        <Grid container>
          <Grid container direction={'column'} justify={'center'} item xs={5} sm={5}>
            {aiListState.data[0].rank.get() < 10 ? (
              <Paper className={classes.cardRankingGreen} elevation={0}>
                <Typography className={classes.rankTextTypo} align={'center'} color="textSecondary" gutterBottom>
                  {t('Rank')}
                </Typography>
                <Typography className={classes.rankNumberTypo} align={'center'} color="textSecondary" gutterBottom>
                  {aiListState.data[0].rank.get()}
                </Typography>
              </Paper>
            ) : (
              <Paper className={classes.cardRanking} elevation={0}>
                <Typography className={classes.rankTextTypo} align={'center'} color="textSecondary" gutterBottom>
                  {t('Rank')}
                </Typography>
                <Typography className={classes.rankNumberTypo} align={'center'} color="textSecondary" gutterBottom>
                  {aiListState.data[0].rank.get()}
                </Typography>
              </Paper>
            )}
          </Grid>

          <Grid container justify={'space-evenly'} item xs={7} sm={7}>
            <Grid container direction={'column'} justify={'center'} item xs={6} sm={6}>
              <Card className={classes.cardScore}>
                <CardContent>
                  <Typography className={classes.textTypo} align={'center'} color="textSecondary" gutterBottom>
                    {t('Overall')}
                  </Typography>
                  <Typography className={classes.numberTypo} align={'center'} color="textSecondary" gutterBottom>
                    {aiListState.data[0].overall.get().toFixed(1)}
                  </Typography>
                </CardContent>
              </Card>
              <Card className={classes.cardScore}>
                <CardContent>
                  <Typography className={classes.textTypo} align={'center'} color="textSecondary" gutterBottom>
                    {t('Technical')}
                  </Typography>
                  <Typography className={classes.numberTypo} align={'center'} color="textSecondary" gutterBottom>
                    {aiListState.data[0].techScore.get().toFixed(1)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid container direction={'column'} justify={'center'} item xs={6} sm={6}>
              <Card className={classes.cardScore}>
                <CardContent>
                  <Typography className={classes.textTypo} align={'center'} color="textSecondary" gutterBottom>
                    {t('Valuation')}
                  </Typography>
                  <Typography className={classes.numberTypo} align={'center'} color="textSecondary" gutterBottom>
                    {aiListState.data[0].valuationScore.get().toFixed(1)}
                  </Typography>
                </CardContent>
              </Card>
              <Card className={classes.cardScore}>
                <CardContent>
                  <Typography className={classes.textTypo} align={'center'} color="textSecondary" gutterBottom>
                    {t('Quality')}
                  </Typography>
                  <Typography className={classes.numberTypo} align={'center'} color="textSecondary" gutterBottom>
                    {aiListState.data[0].gsCore.get().toFixed(1)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  ) : aiListState.data.length === 0 ? (
    <></>
  ) : (
    <Box component="span" m={1}>
      <Grid container>
        <Grid container direction={'column'} justify={'center'} item xs={5} sm={5}>
          {aiListState.data[0].rank.get() < 10 ? (
            <Paper className={mobileClasses.cardRankingGreen} elevation={0}>
              <Typography className={mobileClasses.rankTextTypo} align={'center'} color="textSecondary">
                {t('Rank')}
              </Typography>
              <Typography className={mobileClasses.rankNumberTypo} align={'center'} color="textSecondary">
                {aiListState.data.length > 0 ? aiListState.data[0].rank.get() : null}
              </Typography>
            </Paper>
          ) : (
            <Paper className={mobileClasses.cardRanking} elevation={0}>
              <Typography className={mobileClasses.rankTextTypo} align={'center'} color="textSecondary">
                {t('Rank')}
              </Typography>
              <Typography className={mobileClasses.rankNumberTypo} align={'center'} color="textSecondary">
                {aiListState.data.length > 0 ? aiListState.data[0].rank.get() : null}
              </Typography>
            </Paper>
          )}
        </Grid>

        <Grid container justify={'space-evenly'} item xs={7} sm={7}>
          <Grid container direction={'column'} justify={'center'} item xs={6} sm={6}>
            <Paper className={mobileClasses.cardScore} elevation={0}>
              <Typography className={mobileClasses.textTypo} align={'center'} color="textSecondary">
                {t('Overall')}
              </Typography>
              <Typography className={mobileClasses.numberTypo} align={'center'} color="textSecondary">
                {aiListState.data.length > 0 ? aiListState.data[0].overall.get().toFixed(1) : null}
              </Typography>
            </Paper>
            <Paper className={mobileClasses.cardScore} elevation={0}>
              <Typography className={mobileClasses.textTypo} align={'center'} color="textSecondary">
                {t('Technical')}
              </Typography>
              <Typography className={mobileClasses.numberTypo} align={'center'} color="textSecondary">
                {aiListState.data.length > 0 ? aiListState.data[0].techScore.get().toFixed(1) : null}
              </Typography>
            </Paper>
          </Grid>

          <Grid container direction={'column'} justify={'center'} item xs={6} sm={6}>
            <Paper className={mobileClasses.cardScore} elevation={0}>
              <Typography className={mobileClasses.textTypo} align={'center'} color="textSecondary">
                {t('Valuation')}
              </Typography>
              <Typography className={mobileClasses.numberTypo} align={'center'} color="textSecondary">
                {aiListState.data.length > 0 ? aiListState.data[0].valuationScore.get().toFixed(1) : null}
              </Typography>
            </Paper>
            <Paper className={mobileClasses.cardScore} elevation={0}>
              <Typography className={mobileClasses.textTypo} align={'center'} color="textSecondary">
                {t('Quality')}
              </Typography>
              <Typography className={mobileClasses.numberTypo} align={'center'} color="textSecondary">
                {aiListState.data.length > 0 ? aiListState.data[0].gsCore.get().toFixed(1) : null}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
