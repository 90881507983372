import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './style'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import { AiTableState } from '../../AiMainTable/state'
import { useState } from '@hookstate/core'
import { StockDetailState } from '../state'

export default function StockCodeAndName({ code }) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const aiTableState = useState(AiTableState.list)
  const stockInfoState = useState(StockDetailState.stockInfo)

  console.log(`====i18n.language====${i18n.language}`)

  return (
    <Box component="span" m={1}>
      <Grid container>
        <Grid container item direction={'column'} justify={'flex-start'} xs={2} sm={2}>
          <Typography className={classes.titleTypo} align={'center'} variant="body2" gutterBottom>
            {t('Code')}
          </Typography>
          <Typography className={classes.codeTypo} align={'center'} variant="body2" gutterBottom>
            {aiTableState.data.length > 0 ? aiTableState.data[0].code.get() : null}
          </Typography>
        </Grid>

        <Grid container item xs={8} sm={8} justify={'space-between'}>
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <div style={{ display: 'inline-block' }}>
            <Typography className={classes.titleTypo} align={'center'} variant="body2" gutterBottom>
              {t('Company Name')}
            </Typography>
            <Typography className={classes.nameAndExchangeTypo} align={'center'} variant="body2" gutterBottom>
              {i18n.language === 'vi' ? stockInfoState.fullNameVi.get() : stockInfoState.fullNameEn.get()}
            </Typography>
          </div>
          <Divider className={classes.divider} orientation="vertical" flexItem />
        </Grid>

        <Grid container direction={'column'} justify={'flex-start'} item xs={2} sm={2}>
          <Typography className={classes.titleTypo} align={'center'} variant="body2" gutterBottom>
            {t('Exchange')}
          </Typography>
          <Typography className={classes.nameAndExchangeTypo} align={'center'} variant="body2" gutterBottom>
            HOSE
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
