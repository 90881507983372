import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useMobileStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    chartPaper: {
      width: '100%',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    tableTitleTypo: {
      textDecorationColor: 'rgb(7, 15, 108)',
      fontSize: '24px',
      fontWeight: 700,
    },
    ratioName: {
      fontWeight: 700,
    },
    tableHeader: {
      fontWeight: 700,
    },
    table: {
      // minWidth: 650,
      tableLayout: 'fixed',
    },
    toolbar: {
      justifyContent: 'center',
    },
  })
)
