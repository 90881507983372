import React, { useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useStyles } from './style'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { isScreenBiggerThan, toPc } from '../../../utils/parse'
import { useMobileStyles } from './mobileStyle'
import { StockDetailAction, StockDetailState } from '../state'
import { useState } from '@hookstate/core'

export default function StockRatioTable({ code }) {
  const classes = useStyles()
  const mobileClasses = useMobileStyles()
  const { t, i18n } = useTranslation()

  const stockRatioState = useState(StockDetailState.stockRatio)
  useEffect(() => {
    StockDetailAction.loadStockRatio(code)()
  }, [])

  return isScreenBiggerThan('md') ? (
    <Paper className={classes.paper} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.tableTitleTypo} align={'center'} variant="body2" gutterBottom>
          {t('Financial Ratios')}
        </Typography>
      </Toolbar>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">{t('Ratio')} </TableCell>
            <TableCell align="right">{t('Value')}</TableCell>
            <TableCell align="right">{t('Rank')}</TableCell>
            <TableCell align="right">{t('Ratio')} </TableCell>
            <TableCell align="right">{t('Value')}</TableCell>
            <TableCell align="right">{t('Rank')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.ratioName} align="right">
              ROA
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.roa?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.roa?.ornull?.rank.ornull?.get()}</TableCell>

            <TableCell className={classes.ratioName} align="right">
              ROE
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.roe?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.roe?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>
          <TableRow key={1}>
            <TableCell className={classes.ratioName} align="right">
              EPS
            </TableCell>
            <TableCell align="right">{stockRatioState.eps?.ornull?.amount?.ornull?.get().toFixed(2)}</TableCell>
            <TableCell align="right">{stockRatioState.eps?.ornull?.rank?.ornull?.get()}</TableCell>

            <TableCell align="right" className={classes.ratioName}>
              P/B
            </TableCell>
            <TableCell align="right">{stockRatioState.pb?.ornull?.amount?.ornull?.get().toFixed(2)}</TableCell>
            <TableCell align="right">{stockRatioState.pb?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={2}>
            <TableCell align="right" className={classes.ratioName}>
              P/E
            </TableCell>
            <TableCell align="right">{stockRatioState.pe?.ornull?.amount?.ornull?.get().toFixed(2)}</TableCell>
            <TableCell align="right">{stockRatioState.pe?.ornull?.rank?.ornull?.get()}</TableCell>

            <TableCell align="right" className={classes.ratioName}>
              {t('Gross Profit Margin')}
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.gprofit_margin?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.gprofit_margin?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={3}>
            <TableCell align="right" className={classes.ratioName}>
              {t('Operating Profit Growth')}
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.oprofit_growth?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.nprofit_growth?.ornull?.rank?.ornull?.get()}</TableCell>

            <TableCell align="right" className={classes.ratioName}>
              {t('Net Profit Growth')}
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.nprofit_growth?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.nprofit_growth?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={4}>
            <TableCell className={classes.ratioName} align="right">
              {t('Dividend Yield')}
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.div_y?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.div_y?.ornull?.rank?.ornull?.get()}</TableCell>

            <TableCell align="right" className={classes.ratioName}>
              {t('Debt Equity Ratio')}
            </TableCell>
            <TableCell align="right">{stockRatioState.debt_equity?.ornull?.amount?.ornull?.get().toFixed(2)}</TableCell>
            <TableCell align="right">{stockRatioState.debt_equity?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  ) : (
    <Paper className={mobileClasses.paper} elevation={0}>
      <Toolbar className={mobileClasses.toolbar}>
        <Typography className={mobileClasses.tableTitleTypo} align={'center'} variant="body2" gutterBottom>
          {t('Financial Ratios')}
        </Typography>
      </Toolbar>
      <Table className={mobileClasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right" className={mobileClasses.tableHeader}>
              Ratio
            </TableCell>
            <TableCell align="right" className={mobileClasses.tableHeader}>
              Value
            </TableCell>
            <TableCell align="right" className={mobileClasses.tableHeader}>
              Rank
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={68}>
            <TableCell align="right" className={classes.ratioName}>
              {t('Operating Profit Growth')}
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.oprofit_growth?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.nprofit_growth?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={1}>
            <TableCell align="right" className={mobileClasses.ratioName}>
              ROA
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.roa?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.roa?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={2}>
            <TableCell align="right" className={mobileClasses.ratioName}>
              EPS
            </TableCell>
            <TableCell align="right">{stockRatioState.eps?.ornull?.amount?.ornull?.get().toFixed(2)}</TableCell>
            <TableCell align="right">{stockRatioState.eps?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={4}>
            <TableCell align="right" className={mobileClasses.ratioName}>
              P/E
            </TableCell>
            <TableCell align="right">{stockRatioState.pe?.ornull?.amount?.ornull?.get().toFixed(2)}</TableCell>
            <TableCell align="right">{stockRatioState.pe?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={7}>
            <TableCell align="right" className={mobileClasses.ratioName}>
              {t('Dividend Yield')}
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.div_y?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.div_y?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={6}>
            <TableCell align="right" className={mobileClasses.ratioName}>
              ROE
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.roe?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.roe?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={49}>
            <TableCell align="right" className={classes.ratioName}>
              P/B
            </TableCell>
            <TableCell align="right">{stockRatioState.pb?.ornull?.amount?.ornull?.get().toFixed(2)}</TableCell>
            <TableCell align="right">{stockRatioState.pb?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={3}>
            <TableCell align="right" className={mobileClasses.ratioName}>
              {t('Gross Profit Margin')}
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.gprofit_margin?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.gprofit_margin?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={8}>
            <TableCell align="right" className={mobileClasses.ratioName}>
              {t('Net Profit Growth')}
            </TableCell>
            <TableCell align="right">{toPc(stockRatioState.nprofit_growth?.ornull?.amount?.ornull?.get())}</TableCell>
            <TableCell align="right">{stockRatioState.nprofit_growth?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>

          <TableRow key={5}>
            <TableCell align="right" className={mobileClasses.ratioName}>
              {t('Debt Equity Ratio')}
            </TableCell>
            <TableCell align="right">{stockRatioState.debt_equity?.ornull?.amount?.ornull?.get().toFixed(2)}</TableCell>
            <TableCell align="right">{stockRatioState.debt_equity?.ornull?.rank?.ornull?.get()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}
