import React, { useEffect } from 'react'
import { useStyles } from './style'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { useState } from '@hookstate/core'
import { StockDetailAction, StockDetailState } from '../state'

export default function StockInfo({ code }) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const stockInfoState = useState(StockDetailState.stockInfo)

  useEffect(() => {
    StockDetailAction.getStockInfo(code)()
  }, [])

  return (
    <>
      <Typography className={classes.infoTitleTypo} variant="h6" gutterBottom style={{ paddingTop: 50 }}>
        {t('Company Profile')}
      </Typography>
      <div style={{ paddingLeft: 30, paddingBottom: 20 }}>
        {i18n.language === 'vi'
          ? stockInfoState.profileVi.map((abc) => (
              <Typography className={classes.companyInfoTypo} key={abc.get()} variant="body1" gutterBottom>
                - {abc.get()}
              </Typography>
            ))
          : stockInfoState.profileEn.map((abc) => (
              <Typography className={classes.companyInfoTypo} key={abc.get()} variant="body1" gutterBottom>
                - {abc.get()}
              </Typography>
            ))}
      </div>

      <Typography className={classes.infoTitleTypo} variant="h6" gutterBottom style={{ paddingTop: 25 }}>
        {t('Core Business')}
      </Typography>
      <div style={{ paddingLeft: 30, paddingBottom: 50 }}>
        {i18n.language === 'vi'
          ? stockInfoState.coreBusVi?.map((abc) => (
              <Typography className={classes.companyInfoTypo} key={abc.get()} variant="body1" gutterBottom>
                - {abc.get()}
              </Typography>
            ))
          : stockInfoState.coreBusEn?.map((abc) => (
              <Typography className={classes.companyInfoTypo} key={abc.get()} variant="body1" gutterBottom>
                - {abc.get()}
              </Typography>
            ))}
      </div>
    </>
  )
}
