import { createState } from '@hookstate/core'
import axiosProvider from '../../utils/axiosProvider'
import { API } from '../../constant'
import { IStockInfo, IStockRatio } from './interface'
import { postAxios } from '../../utils/parse'

export const StockDetailState = {
  stockInfo: createState<IStockInfo>({
    profileVi: [],
    profileEn: [],
    coreBusVi: [],
    coreBusEn: [],
  }),
  stockRatio: createState<IStockRatio>({}),
}

export const StockDetailAction = {
  getStockInfo(code: string) {
    return async () => {
      try {
        StockDetailState.stockInfo.set({
          profileVi: [],
          profileEn: [],
          coreBusVi: [],
          coreBusEn: [],
        })

        const response = await postAxios(API.STOCK_DETAIL.getStockInfo, { code })

        StockDetailState.stockInfo.set(response)
      } catch (err) {
        StockDetailState.stockInfo.set({
          profileVi: [],
          profileEn: [],
          coreBusVi: [],
          coreBusEn: [],
        })
      }
    }
  },
  loadStockRatio(code: string) {
    return async () => {
      try {
        StockDetailState.stockRatio.set({})

        const aiRatingList = await postAxios(API.STOCK_DETAIL.getRatio, { code })

        StockDetailState.stockRatio.set(aiRatingList)
      } catch (err) {
        StockDetailState.stockRatio.set({})
      }
    }
  },
}
