import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    chartPaper: {
      width: '100%',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    codeTypo: {
      color: 'rgb(15, 105, 255)',
      fontWeight: 700,
      fontSize: '22px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      // lineHeight: '18.2px',
    },
    titleTypo: {
      textDecorationColor: 'rgb(7, 15, 108)',
      fontSize: '13px',
      fontWeight: 700,
      // lineHeight: '42px',

      backgroundColor: 'rgba(0, 0, 0, 0)',
      color: 'rgb(7, 15, 108)',
    },
    nameAndExchangeTypo: {
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      color: 'rgb(15, 105, 255)',
    },
    table: {
      // minWidth: 650,
      tableLayout: 'fixed',
    },
    divider: {
      // justify: 'flex-end'
      width: '1px',
      // // height: '184px',
      // marginLeft: '20px',
      // marginRight: '20px',
    },
    cardRanking: {
      // width: '100%',
      // height: '100%',
      // marginBottom: theme.spacing(3),
      margin: theme.spacing(1),
      // marginLeft: theme.spacing(2),
      position: 'relative',
      width: '90%',
      paddingBottom: '90%',
      height: 0,
    },
    cardScore: {
      // width: '100%',
      // height: '100%',
      // marginBottom: theme.spacing(3),
      margin: theme.spacing(1),
      // marginRight: theme.spacing(2),
      position: 'relative',
      width: '80%',
      paddingBottom: '80%',
      height: 0,
    },
  })
)
