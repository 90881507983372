// @ts-nocheck
import React from 'react'
import { Router } from '@reach/router'
import StockDetail from '../../components/StockDetail/StockDetail'
import SEO from '../../components/seo'
import Layout from '../../components/Layout'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t, i18n } = useTranslation()

  return (
    <Layout>
      <SEO title={t('Code')} />
      <Router>
        <StockDetail path={`/code/:slug`} />
      </Router>
    </Layout>
  )
}
