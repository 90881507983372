import { createStyles, makeStyles } from '@material-ui/core/styles'
import CONF from '../../../config'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    textTypo: {
      // color: 'rgb(255, 255, 255)',
      // color: 'rgb(15, 105, 255)',
      color: CONF.css.white,
      fontWeight: 700,
      fontSize: '13px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
    },
    rankTextTypo: {
      // color: 'rgb(255, 255, 255)',
      // color: 'rgb(15, 105, 255)',
      marginTop: theme.spacing(2),
      color: CONF.css.white,
      fontWeight: 700,
      fontSize: '20px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
    },
    rankNumberTypo: {
      color: CONF.css.white,
      fontWeight: 700,
      fontSize: '90px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
    },
    numberTypo: {
      // color: 'rgb(255, 255, 255)',
      // color: 'rgb(15, 105, 255)',
      color: CONF.css.white,
      fontWeight: 700,
      fontSize: '45px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
    },
    cardRanking: {
      margin: theme.spacing(1),
      position: 'relative',
      width: '90%',
      paddingBottom: '90%',
      height: 0,
      background: CONF.css.blue,
    },
    cardRankingGreen: {
      margin: theme.spacing(1),
      position: 'relative',
      width: '90%',
      paddingBottom: '90%',
      height: 0,
      background: CONF.css.green,
    },
    cardScore: {
      // width: '100%',
      // height: '100%',
      // marginBottom: theme.spacing(3),
      marginBottom: theme.spacing(0),
      margin: theme.spacing(1),
      // marginRight: theme.spacing(2),
      position: 'relative',
      width: '85%',
      paddingBottom: '85%',
      height: 0,
      // backgroundColor: CONF.css.blue,
      // backgroundImage: 'linear-gradient(rgb(15,105,255), rgb(11,71,171))',
      background: CONF.css.blue,
    },
  })
)
