import React from 'react'
import StockRatioTable from './StockRatioTable/StockRatioTable'
import Grid from '@material-ui/core/Grid'
import StockCodeAndName from './StockCodeAndName/StockCodeAndName'
import Paper from '@material-ui/core/Paper'
import { useStyles } from './style'
import StockAiScore from './StockAiScore/StockAiScore'
import StockInfo from './StockInfo/StockInfo'

export default function StockDetail({ slug }) {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={3}>
        <Grid container item xs={12} sm={5}>
          <Paper className={classes.paper} elevation={0}>
            <StockCodeAndName code={{ slug }} />
            <StockAiScore code={slug} />
          </Paper>
        </Grid>
        <Grid container item xs={12} sm={7} justify="center">
          <StockRatioTable code={slug} />
        </Grid>
      </Grid>

      <StockInfo code={slug} />
    </>
  )
}
